import { Component, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, FormControl } from "@angular/forms";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import { MembersService } from "../../services/members/members.service"; //import
import Swal, { SweetAlertType } from "sweetalert2";
import { HttpResponse, HttpResult } from "../../models/utils/http-response";
import { concat, Observable, of, Subject } from "rxjs";
import { Occupation } from "../../models/masters/occupation";
import { Qualification } from "../../models/masters/qualification";
import { Relation } from "../../models/masters/relation";
import { QualificationsService } from "../../services/masters/qualifications.service";
import { OccupationsService } from "../../services/masters/occupations.service";
import { RelationsService } from "../../services/masters/relations.service";
import { MemberList } from "../../models/members/member-list";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { Country } from "../../models/masters/country";
import { District } from "../../models/masters/district";
import { Panchayath } from "../../models/masters/panchayath";
import { State } from "../../models/masters/state";
import { CountriesService } from "../../services/masters/countries.service";
import { DistrictsService } from "../../services/masters/districts.service";
import { PanchayathsService } from "../../services/masters/panchayaths.service";
import { StatesService } from "../../services/masters/states.service";

@Component({
  selector: "app-dashbaord",
  templateUrl: "./dashbaord.component.html",
  styleUrls: ["./dashbaord.component.css"],
})
export class DashbaordComponent implements OnInit {
  public memberForm: FormGroup;
  public familyList: FormArray;

  Status = [
    { Status: "A", StatusName: "Active" },
    { Status: "E", StatusName: "Expired" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  EmergencyContact1Type = [
    { EmergencyContact1Type: "R", EmergencyContact1TypeName: "Relative" },
    { EmergencyContact1Type: "F", EmergencyContact1TypeName: "Friend" },
  ];
  EmergencyContact2Type = [
    { EmergencyContact2Type: "R", EmergencyContact2TypeName: "Relative" },
    { EmergencyContact2Type: "F", EmergencyContact2TypeName: "Friend" },
  ];
  countries$: Observable<Country[]>;
  states$: Observable<State[]>;
  districts$: Observable<District[]>;
  panchayaths$: Observable<Panchayath[]>;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  Member: number;
  MemberName: string;

  qualifications$: Observable<Qualification[]>;
  relations$: Observable<Relation[]>;
  occupations$: Observable<Occupation[]>;

  constructor(
    private memberService: MembersService,
    private qualificationService: QualificationsService,
    private relationService: RelationsService,
    private occupationService: OccupationsService,
    private countryService: CountriesService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private panchayathService: PanchayathsService,
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.qualifications$ = this.qualificationService.getQualifications();
    this.relations$ = this.relationService.getRelations();
    this.occupations$ = this.occupationService.getOccupations();
    this.countries$ = this.countryService.getCountries();

    this.loadMembers();
    this.memberForm = this.formBuilder.group({
      FullName: new FormControl(
        JSON.parse(localStorage.getItem("userMemberDetails")).Name
      ),
      Member: new FormControl({
        Id: JSON.parse(localStorage.getItem("userMemberDetails")).Member,
        Name: JSON.parse(localStorage.getItem("userMemberDetails")).Name,
      }),
      MemberName: new FormControl(
        JSON.parse(localStorage.getItem("userMemberDetails")).Name
      ),
      Id: new FormControl(0),
      KKMAId: new FormControl(""),
      CivilID: new FormControl(""),
      Mobile: new FormControl(""),
      WhatsApp: new FormControl(""),
      HAPanchayath: new FormControl(0),
      HAPanchayathName: new FormControl(""),
      HADistrict: new FormControl(0),
      HADistrictName: new FormControl(""),
      HAState: new FormControl(0),
      HAStateName: new FormControl(""),
      HACountry: new FormControl(0),
      HACountryName: new FormControl(""),
      ExeHAMobile: new FormControl(""),
      HAMobile: new FormControl(""),
      EmergencyContact1: new FormControl(""),
      EmergencyContact1Type: new FormControl("R"),
      EmergencyContact1TypeName: new FormControl(""),
      EmergencyContact1Phone: new FormControl(""),
      EmergencyContact2: new FormControl(""),
      EmergencyContact2Type: new FormControl("R"),
      EmergencyContact2TypeName: new FormControl(""),
      EmergencyContact2Phone: new FormControl(""),
      FamilyDetails: this.formBuilder.array([]),
    });
    this.familyList = this.memberForm.get("FamilyDetails") as FormArray;

    this.getMember();
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersByStarClubList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getMember() {
    this.memberService.getMember(this.memberForm.value.Member.Id).subscribe(
      (member: any) => this.editMember(member),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
      }
    );
  }

  editMember(member: any) {
    console.log(member);
    this.removeFamilyOnReset();
    if (member != null) {
      this.memberForm.patchValue({
        Id: member.Id,
        FullName: member.MemberName,
        KKMAId: member.KKMAId,
        CivilID: member.CivilID,
        Mobile: member.Mobile,
        WhatsApp: member.WhatsApp,
        EmergencyContact1: member.EmergencyContact1,
        EmergencyContact1Type: member.EmergencyContact1Type,
        EmergencyContact1Phone: member.EmergencyContact1Phone,
        EmergencyContact2: member.EmergencyContact2,
        EmergencyContact2Type: member.EmergencyContact2Type,
        EmergencyContact2Phone: member.EmergencyContact2Phone,
        HAHouseName: member.HAHouseName,
        HAPlace: member.HAPlace,
        HACountry: member.HACountry,
        HACountryName: member.HACountryName,
        HAState: member.HAState,
        HAStateName: member.HAStateName,
        HADistrict: member.HADistrict,
        HADistrictName: member.HADistrictName,
        HAPostOffice: member.HAPostOffice,
        HAPostOfficeName: member.HAPostOfficeName,
        HAPinCode: member.HAPinCode,
        HAPanchayath: member.HAPanchayath,
        HAPanchayathName: member.HAPanchayathName,
        HAMobile: member.HAMobile,
        FamilyDetails: [],
      });
      this.createFormFamily(member);
    } else {
      this.memberForm.patchValue({
        KKMAId: this.memberForm.value.Member.Name.substring(0, 9),
      });
    }
  }

  saveMember() {
    console.log(this.memberForm.value);
    const member: any = {
      member: this.memberForm.value,
    };

    console.log(member);
    this.memberService.modifyMember(member).subscribe(
      (response: HttpResponse) => {
        if (response.result === HttpResult.Success) {
          this.displayFeedback("success", "Saved Successfully!", "");
          this.removeFamilyOnReset();
          this.getMember();
        } else {
          this.displayFeedback(
            "warning",
            response.result + "!",
            response.message
          );
        }
      },
      (error) => {
        this.displayFeedback("error", "Error!", error);
      }
    );
  }

  getStates(country: number) {
    this.states$ = this.stateService.getCountryStates(country);
    this.districts$ = this.districtService.getStateDistricts(0);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
  }

  getDistricts(state: number) {
    this.districts$ = this.districtService.getStateDistricts(state);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
  }

  getPanchayathsAndPO(district: number) {
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(district);
  }

  changeAge(index: number) {
    let familyList: any[];
    familyList = this.memberForm.value.FamilyDetails;
    if (familyList[index].IsAge) {
      let d = new Date();

      let age = familyList[index].Age;
      var dob = new Date(d.getFullYear() - age, d.getMonth(), d.getDate());
      familyList[index].DateOfBirth = dob;
    } else {
      let d = new Date();
      let age = this.dateDifference(d, familyList[index].DateOfBirth) / 365;
      familyList[index].Age = Math.trunc(age);
    }

    this.memberForm.patchValue({
      FamilyDetails: familyList,
    });
  }

  dateDifference(fromDate: Date, toDate: Date) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    return (
      Math.floor(
        (Date.UTC(
          fromDate.getFullYear(),
          fromDate.getMonth(),
          fromDate.getDate()
        ) -
          Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }
  reset() {
    this.removeFamilyOnReset();
    this.memberForm.patchValue({
      FullName: JSON.parse(localStorage.getItem("userMemberDetails")).Name,
      Member: {
        Id: JSON.parse(localStorage.getItem("userMemberDetails")).Member,
        Name: JSON.parse(localStorage.getItem("userMemberDetails")).Name,
      },
      MemberName: JSON.parse(localStorage.getItem("userMemberDetails")).Name,
    });
    this.getMember();
  }

  delete() {
    let member = 0;

    if (this.memberForm.value.Member == null) {
      member = 0;
    } else {
      member = this.memberForm.value.Member.Id;
    }
    if (member == 0) {
      this.displayFeedback("warning", "Please select a member!", "");
    } else {
      if (confirm("Are you sure to delete the selected member family?")) {
        this.memberService.deleteMember(member).subscribe(
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback("success", "Deleted Successfully!", "");
              this.getMember();
            } else {
              this.displayFeedback(
                "warning",
                response.result + "!",
                response.message
              );
            }
          },
          (error) => {
            this.displayFeedback("error", "Error!", error);
          }
        );
      }
    }
  }

  get familyFormGroup() {
    return this.memberForm.get("FamilyDetails") as FormArray;
  }

  createFormFamily(member: any) {
    for (const family of member.FamilyDetails) {
      this.familyList.push(
        this.createFamilyWithValues(
          family.Type,
          family.Id,
          family.Member,
          family.MemberName,
          family.Name,
          family.Relation,
          family.RelationName,
          family.IsAge,
          family.Age,
          family.DateOfBirth,
          family.Qualification,
          family.QualificationName,
          family.Occupation,
          family.OccupationName,
          family.IsResidentInKuwait,
          family.Status
        )
      );
    }
  }

  createFamilyWithValues(
    Type: string,
    Id: number,
    Member: number,
    MemberName: string,
    Name: string,
    Relation: number,
    RelationName: string,
    IsAge: boolean,
    Age: boolean,
    DateOfBirth: Date,
    Qualification: number,
    QualificationName: string,
    Occupation: number,
    OccupationName: string,
    IsResidentInKuwait: boolean,
    Status: string
  ): FormGroup {
    return this.formBuilder.group({
      Type: new FormControl(Type),
      Id: new FormControl(Id),
      Member: new FormControl(Member),
      MemberName: new FormControl(MemberName),
      Name: new FormControl(Name),
      Relation: new FormControl(Relation),
      RelationName: new FormControl(RelationName),
      IsAge: new FormControl(IsAge),
      Age: new FormControl(Age),
      DateOfBirth: new FormControl(DateOfBirth),
      Qualification: new FormControl(Qualification),
      QualificationName: new FormControl(QualificationName),
      Occupation: new FormControl(Occupation),
      OccupationName: new FormControl(OccupationName),
      IsResidentInKuwait: new FormControl(IsResidentInKuwait),
      Status: new FormControl(Status),
    });
  }

  createFamily(): FormGroup {
    return this.formBuilder.group({
      Type: new FormControl("F"),
      Id: new FormControl(0),
      Member: new FormControl(this.memberForm.value.Member.Id),
      MemberName: new FormControl(this.memberForm.value.Member.Name),
      Name: new FormControl(""),
      Relation: new FormControl(0),
      RelationName: new FormControl(""),
      IsAge: new FormControl(false),
      Age: new FormControl(0),
      DateOfBirth: new FormControl(new Date()),
      Qualification: new FormControl(0),
      QualificationName: new FormControl(""),
      Occupation: new FormControl(0),
      OccupationName: new FormControl(""),
      IsResidentInKuwait: new FormControl(false),
      Status: new FormControl("A"),
    });
  }

  addFamily() {
    this.familyList.push(this.createFamily());
  }

  removeFamilyOnReset() {
    for (let i = this.memberForm.value.FamilyDetails.length - 1; i >= 0; i--) {
      this.removeFamily(i);
    }
  }

  removeFamily(index) {
    this.familyList.removeAt(index);
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
