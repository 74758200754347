import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Occupation } from "../../models/masters/occupation";
import { HttpResponse } from "../../models/utils/http-response";

@Injectable({
  providedIn: "root",
})
export class OccupationsService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getOccupations(): Observable<Occupation[]> {
    return this.http.get<Occupation[]>(environment.root + "/Occupation");
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
